import { ActionSheetController, GestureController, Platform } from "@ionic/angular";
import { PhoneService } from "../../webphone/phone.service";
import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CrudService } from "../../../services/crud.service";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { UtilService } from "../../../services/util.service";
import { DIALER_THEME_CONFIGURATION } from "src/app/utils/config";



@Component({
  selector: 'app-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss'],
})
export class IncomingCallComponent implements OnInit, OnDestroy {
  @Input() groupedContacts: any[] = [];

  public showKeypad: boolean = false;
  public dialerThemeSetting = DIALER_THEME_CONFIGURATION.light
  public userRoomNumber = this.phoneService.getParameter('displayname');
  private lastOnStart: number = 0;
  private DOUBLE_CLICK_THRESHOLD: number = 500;
  timer: any = 0;
  preventSimpleClick: boolean = false;
  status: string;
  @ViewChild('disconnected', {
    static: false
  }) disconnected: any;
  @ViewChild('appTimer', {
    static: false
  }) appTimer: any;
  private _keypadValueSubscriber: Subscription = null;
  audioIncoming: HTMLAudioElement;
  muteOn: boolean = false
  onCallStateChangedEventSubscriber: Subscription = null;
  public isCalling: any = null;
  public selectedDevice: string = '';
  private _muteSubscriber: Subscription = null;
  private _pwaConfigurationSubscribe: Subscription = null;
  public isMute: boolean = false;
  public deviceLists: any[] = [];
  constructor(
    private phoneService: PhoneService,
    private crudService: CrudService,
    private gestureCtrl: GestureController,
    private platform: Platform,
    private _zone: NgZone,
    private actionSheetController: ActionSheetController,
    private utilService: UtilService
  ) { }

  ngOnDestroy(): void {
    this.onCallStateChangedEventSubscriber?.unsubscribe();
    this._keypadValueSubscriber?.unsubscribe();
    this._pwaConfigurationSubscribe?.unsubscribe();
    this._muteSubscriber?.unsubscribe();
    this._keypadValueSubscriber?.unsubscribe();
  }

  async ngOnInit() {
    setTimeout(async () => {
      const { deviceLists, selectedDevice } =
        await this.phoneService.getDeviceList();
      this.deviceLists = deviceLists as any[];
      this.selectedDevice = selectedDevice || this.deviceLists[0]?.label;
    }, 5000);
    this.phoneService.keypadModel.next({
      to: this.phoneService.keypadModel.value.to, showKeypad: false
    });
    this.onCallStateChangedEventSubscriber = this.phoneService.isCalling$.pipe(tap((e) => {
      this._zone.run(() => {
        this.isCalling = e;
        if (!e || !e?.callStatus) {
          this.phoneService.keypadModel.next({
            to: '', showKeypad: true
          })
        }
      })
    })).subscribe();
    this._keypadValueSubscriber = this.phoneService.keypadModel
      .pipe(tap((value: {
        to: string; showKeypad: boolean;
      }) => {
        this.showKeypad = value.showKeypad
      }))
      .subscribe();
      this._muteSubscriber = this.phoneService.isMute$
      .pipe(tap((isMute) => {
        this.isMute = isMute
      }))
      .subscribe();
    setTimeout(() => {
      const disconnectedGesture = this.gestureCtrl.create({
        gestureName: 'dbclick',
        el: this.disconnected.nativeElement as any,
        threshold: 0,
        onStart: () => { this.onStart(); }
      });
      disconnectedGesture.enable();
    }, 1000)
    this._pwaConfigurationSubscribe = this.crudService.pwaConfiguration$.pipe(
      tap((pwaConfiguration) => {
        this.dialerThemeSetting = pwaConfiguration?.themeConfiguration[this.utilService.isDarkMode()] || DIALER_THEME_CONFIGURATION[this.utilService.isDarkMode()];;
      })
    ).subscribe();
  }


  async acceptIncomingCall() {
    this._zone.run(() => {
      this.phoneService.acceptCall();
      this.appTimer?.startTimer();
    })
  }

  async terminateIncomingCall() {
    this._zone.run(() => {
      this.appTimer?.clearTimer();
      this.phoneService.terminateCall();
    })
  }

  
  async muteUnmuteCall() {
    await this.phoneService.muteUnmuteCall(!this.phoneService.isMute$.getValue())
  }


  simpleClickFunction(): void {
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 400;

    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        //whatever you want with simple click go here
        this._zone.run(() => {
          if (this.platform.is('ios') || this.platform.is('iphone') || this.platform.is('ipad')) {
            this.terminateIncomingCall();
          }
        })
      }
    }, delay);

  }

  doubleClickFunction(): void {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    this._zone.run(() => {
      this.terminateIncomingCall();
    })
  }

  private onStart() {
    const now = Date.now();
    if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
      this.doubleClickFunction();
      this.lastOnStart = 0;
    } else {
      this.lastOnStart = now;
      this.simpleClickFunction();
    }
  }

  
  keypadShowHide() {
    this.phoneService.keypadModel.next({
      to: this.phoneService.keypadModel.value.to, showKeypad: !this.phoneService.keypadModel.value.showKeypad
    })
  }

  async onSelectDevice() {
    try {
      await this.phoneService.setdevice(this.selectedDevice);
    } catch (error) {
      console.error('error ===============', error);
    }
  }
}
