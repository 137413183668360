import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, GestureController, Platform } from '@ionic/angular';
import { CrudService } from '../../../services/crud.service';
import { PhoneService } from '../../webphone/phone.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilService } from '../../../services/util.service';
import { DIALER_THEME_CONFIGURATION } from '../../../utils/config';

@Component({
  selector: 'outgoing-call',
  templateUrl: './outgoing-call.component.html',
  styleUrls: ['./outgoing-call.component.scss']
})
export class OutgoingCallComponent implements OnInit, OnDestroy {
  public dialerThemeSetting = DIALER_THEME_CONFIGURATION.light
  @Input() groupedContacts: any[] = [];

  public userRoomNumber = this.phoneService.getParameter('displayname');
  @ViewChild('disconnected', {
    static: false,
  })
  disconnected: any;
  private lastOnStart: number = 0;
  private DOUBLE_CLICK_THRESHOLD: number = 500;
  timer: any = 0;
  preventSimpleClick: boolean = false;
  audioOutgoing: HTMLAudioElement;
  @ViewChild('appTimer', {
    static: false,
  })
  appTimer: any;
  onCallStateChangedEventSubscriber: Subscription = null;
  private registerSubscriber: Subscription = null;
  public isWTCRegistered = false;
  public isCalling: any = null;
  private _keypadValueSubscriber: Subscription = null;
  public keypadModel: string = '';
  public showKeypad: boolean = true;
  public selectedDevice: string = '';
  public isMute: boolean = false;
  private _muteSubscriber: Subscription = null;
  private _pwaConfigurationSubscribe: Subscription = null;
  public deviceLists: any[] = [];
  constructor(
    private platform: Platform,
    private gestureCtrl: GestureController,
    private _zone: NgZone,
    private crudService: CrudService,
    private phoneService: PhoneService,
    private utilService: UtilService
  ) {}

  async ngOnInit() {
    setTimeout(async () => {
      const { deviceLists, selectedDevice } =
        await this.phoneService.getDeviceList();
      this.deviceLists = deviceLists as any[];
      this.selectedDevice = selectedDevice || this.deviceLists[0]?.label;
    }, 5000);
    this.onCallStateChangedEventSubscriber =
      this.phoneService.isCalling$.subscribe(async (e) => {
        this.isCalling = e;
        if (!e || !e?.callStatus) {
          this.phoneService.keypadModel.next({
            to: '',
            showKeypad: true,
          });
          this.appTimer?.clearTimer();
        } else if (e.callStatus === 'connected') {
          this.phoneService.keypadModel.next({
            to: this.phoneService.keypadModel.value.to,
            showKeypad: false,
          });
          this.appTimer?.startTimer();
        } else {
          this.appTimer?.clearTimer();
        }
      });
    this.registerSubscriber = this.phoneService.isUserRegistered$
      .pipe(
        tap((value: boolean) => {
          this.isWTCRegistered = value;
        })
      )
      .subscribe();
    this._keypadValueSubscriber = this.phoneService.keypadModel
      .pipe(
        tap((value: { to: string; showKeypad: boolean }) => {
          this.keypadModel = value.to;
          this.showKeypad = value.showKeypad;
        })
      )
      .subscribe();
    this._muteSubscriber = this.phoneService.isMute$
      .pipe(
        tap((isMute) => {
          this.isMute = isMute;
        })
      )
      .subscribe();
    setTimeout(() => {
      const disconnectedGesture = this.gestureCtrl.create({
        gestureName: 'dbclick',
        el: this.disconnected.el as any,
        threshold: 0,
        onStart: () => {
          this.onStart();
        },
      });
      disconnectedGesture.enable();
    }, 1000);
    this._pwaConfigurationSubscribe = this.crudService.pwaConfiguration$
      .pipe(
        tap((pwaConfiguration) => {
          this.dialerThemeSetting =
            pwaConfiguration?.themeConfiguration[
              this.utilService.isDarkMode()
            ] || DIALER_THEME_CONFIGURATION[this.utilService.isDarkMode()];
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.onCallStateChangedEventSubscriber) {
      this.onCallStateChangedEventSubscriber.unsubscribe();
    }
    this.registerSubscriber?.unsubscribe();
    this._pwaConfigurationSubscribe?.unsubscribe();
    this._muteSubscriber?.unsubscribe();
    this._keypadValueSubscriber?.unsubscribe();
  }

  simpleClickFunction(): void {
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 400;

    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        //whatever you want with simple click go here
        this._zone.run(() => {
          if (
            this.platform.is('ios') ||
            this.platform.is('iphone') ||
            this.platform.is('ipad')
          ) {
            this.phoneService.terminateCall();
          }
        });
      }
    }, delay);
  }

  doubleClickFunction(): void {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    this._zone.run(() => {
      this.phoneService.terminateCall();
    });
  }

  private onStart() {
    const now = Date.now();
    if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
      this.doubleClickFunction();
      this.lastOnStart = 0;
    } else {
      this.lastOnStart = now;
      this.simpleClickFunction();
    }
  }

  async muteUnmuteCall() {
    await this.phoneService.muteUnmuteCall(
      !this.phoneService.isMute$.getValue()
    );
  }

  keypadShowHide() {
    this.phoneService.keypadModel.next({
      to: this.phoneService.keypadModel.value.to,
      showKeypad: !this.phoneService.keypadModel.value.showKeypad,
    });
  }

  async terminateOutgoingCall() {
    await this.phoneService.terminateCall();
  }

  async onSelectDevice() {
    try {
      await this.phoneService.setdevice(this.selectedDevice);
    } catch (error) {
      console.error('error ===============', error);
    }
  }

}
