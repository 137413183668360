export const WEB_PUSH_SERVER_KEY =
  'BPOcQ21Q_EK69foz5rR_GpbHsVF5Icf1kaSATeLaeWH4M6Fh3QLcRFVljl2zlmMvEf5bcMgJCoDZwkH1OJXMH3Q';
export const GOOGLE_API_KEY = 'AIzaSyBdOV-WIkBRaiVjfvv4PZWMM4gJwgy3ySk';
export const THEME_SETTING = {
  backgroundColor: '#ffffff',
  buttonBackgroundColor: '#000000',
  buttonTextColor: '#ffffff',
  dialButtonTextColor: '#ffffff',
  dialButtonBackgroundColor: '#90ee90',
  buttonAlphabetColor: '#d3d3d3',
  inputBackgroundColor: '#ffffff',
  inputColor: '#0C2753',
  instructionTextColor: '#000000',
  suggestionTextColor: '#000000',
  headerFooterThemeColor: '#000f4e',
};

export const WAKE_WORD_KEYWORD = {
  GENIUS: 'Genie',
};

export const DIALER_THEME_CONFIGURATION = {
  light: {
    quickButton: {
      bgColor: '#F4F4F4',
      textColor: '#272727',
      iconTextColor: '#FFF',
      iconBgColor: '#0086FF',
      callingIcon: '../../../../assets/icon/quick_call.png',
      tabHoverIcon: 'path/to/tab-hover-icon-dark.svg',
      headingColor: '#0C2753',
      hoverBGColor: '#60BDFF',
      hoverTextColor: '#0C2753',
      hoverIconTextColor: '#FFF',
      hoverIconBgColor: '#60BDFF',
    },
    backgroundColor:
      'linear-gradient(180deg, rgba(63, 146, 229, 0.17) 50.39%, rgba(27, 87, 185, 0.05) 93.84%)',
    dialInput: {
      bgColor: '#FFF',
      textColor: '#192571',
      backspaceIconColor: "#FF6161"
    },

    dialButton: {
      bgColor: '#60BDFF',
      textColor: '#0C2753',
      alphabetColor: '#0C2753',
      voiceMailIcon: '../../../../assets/icon/voicemail_dark_ic.png',
      phoneDetailIcon: '.../../assets/icon/phone_diary_dark_ic.png',
    },
    dialCallButton: {
      bgColor: '#34C85A',
      textColor: '#F4F4F4',
      callIcon: '.../../assets/icon/light_call_ic.png',
      callEndTextColor: '#FFFF',
      callEndBgColor: '#FF6161',
    },
    instructionTextColor: '#272727',
    buttonStyle: {
      shape: 'round',
    },
  },
  dark: {
    quickButton: {
      bgColor: '#FFF',
      textColor: '#272727',
      iconTextColor: '#FFF',
      iconBgColor: '#0086FF',
      callingIcon: '../../../../assets/icon/quick_call.png',
      tabHoverIcon: 'path/to/tab-hover-icon-dark.svg',
      headingColor: '#FFF',
      hoverBGColor: '#0086FF',
      hoverTextColor: '#FFF',
      hoverIconTextColor: '#0086FF',
      hoverIconBgColor: '#FFF',
    },
    backgroundColor:
      'linear-gradient(180deg, rgba(12, 39, 83, 0.43) 9.47%, rgba(63, 146, 229, 0.17) 50.39%, rgba(27, 87, 185, 0.05) 93.84%), #0056AB',
    suggestionNoteText: '#FFF',
    dialInput: {
      bgColor: '#FFF',
      textColor: '#192571',
      backspaceIconColor: "#FF6161"
    },
    dialButton: {
      bgColor: '#192571',
      textColor: '#F4F4F4',
      alphabetColor: '#F4F4F4',
      voiceMailIcon: '../../../../assets/icon/voicemail_ic.png',
      phoneDetailIcon: '.../../assets/icon/phone_diary.png',
    },
    dialCallButton: {
      bgColor: '#34C85A',
      textColor: '#F4F4F4',
      callIcon: '.../../assets/icon/light_call_ic.png',
      callEndTextColor: '#FFFF',
      callEndBgColor: '#FF6161',
    },
    instructionTextColor: '#ffffff',
    buttonStyle: {
      shape: 'round',
    },
  },
};


export const ROOT_CONFIGURATION = {
  'ellis': {
    logoPath:
      'https://hotelgenie-bucket.apigenie.app/admin/logo/ellis.png',
    headerLogoPath: 'https://hotelgenie-bucket.apigenie.app/admin/logo/ellis.png',
    background: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-back-default.png'
  },
  'lumea': {
    logoPath:
      'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-logo.png',
    headerLogoPath: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-logo.png',
    background: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-back.jpeg'
  },
  'lumeabythesea.com': {
    logoPath:
      'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-logo.png',
    headerLogoPath: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-logo.png',
    background: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-back.jpeg'
  },
  'lumeabythesea': {
    logoPath:
      'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-logo.png',
    headerLogoPath: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-logo.png',
    background: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-back.jpeg'
  },
  default: {
    logoPath: './assets/icon/hg_logo_2_color_orange_blue.png',
    headerLogoPath: 'assets/icon/hotel-genie-logo.png',
    background: 'https://storage.googleapis.com/hgapp-bkt/0-1711536620966-1711536620966-back-default.png'
  },
};