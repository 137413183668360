import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { v4 as uuidv4 } from 'uuid';
import { get, debounce } from 'lodash-es';
import { environment } from '../../../environments/environment';
import { CrudService } from 'src/app/services/crud.service';
import { tap } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { PhoneService } from '../webphone/phone.service';
import { NavigationEnd, Router } from '@angular/router';
import { LicenseManager, KeywordDetector } from 'keyword-detection-web';

// Colors to interpolate between
const color1 = { r: 159, g: 192, b: 252 }; // #93DEEE
const color2 = { r: 200, g: 200, b: 200 }; // #558EC1
// Adjust the amplifier values
const audioAmplifierOuter = 5; // Responsiveness of the outer circle particles
const audioAmplifierInner = 100; // Responsiveness of the inner green circle
const audioAmplifierSVG = 700; // Responsiveness of the SVG vibration
const audioAmplifierSVGColor = 500; // Softens the color transition for the SVG
// Line width for the inner circle
const continuesListeningTimeout = 10000; // Thicker line for the inner circle
@Component({
  selector: 'app-wake-word',
  templateUrl: './wake-word.component.html',
  styleUrls: ['./wake-word.component.scss'],
})
export class WakeWordComponent implements OnInit, OnDestroy {
  private _isInitialize: boolean = false;
  private _orientation: string = '';
  public transcript: string = "";
  public transcripts: {
    direction: string;
    message: string
  }[] = [];
  public guestLastTranscript: string = '';
  public botLastTranscript: string = '';
  public speech: any = null;
  public showWakeWordComponent: boolean = false;
  private _recognition: any;
  private _ws: WebSocket;
  private _callId: string = uuidv4();
  private _currentMsgId: string = '';
  private _audioInstance: AudioBufferSourceNode = null;
  private _voiceAgentData: any = null;
  public isReadyWakeWordComponent: boolean = false;
  public isListening: boolean = false;
  public loadingAnswer: boolean = false;
  public speakingAnswer: boolean = false;
  public continuesListening: boolean = false;
  private _wakeWordAnimation: {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    canvasX: number;
    canvasY: number;
    particles: { angle: number, radius: number }[]
  } = {
      canvas: null, ctx: null, canvasX: 0, canvasY: 0, particles: []
    }
  private _audioQueue = [];
  private _clearStopRecognitionTimeout = null;
  private _analyserInstance = {
    analyser: null,
    dataArray: null,
  };
  private _customAction = null;
  private _orientationSubscribe: Subscription;
  private _propertyConfigSubscribe: Subscription;
  private _callingNumbers = [];
  public _statusOfWakeWordInit: string = 'initializing';
  private _keywordDetector: Record<string, any> = {};
  private _pingTimeoutInstance = null;
  private _debounceOnShowHideWakeWordComponent = null;
  public botAnimation: {
    width: number;
    height: number;
    top: string;
  } = {
      width: 30,
      height: 30,
      top: '24%',
    }
  public canvasClass: string = 'home-canvas'; // default canvas class for positioning
  constructor(private readonly utilService: UtilService, private readonly crudService: CrudService, private _zone: NgZone, private phoneService: PhoneService, private router: Router) {
    this._debounceOnShowHideWakeWordComponent = debounce(this.onShowHideWakeWordComponent, 300);
  }

  public async initSpeechRecognition(): Promise<void> {
    try {
      if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
        // Create a new instance of the SpeechRecognition object
        const SpeechRecognition = window['SpeechRecognition'] || window['webkitSpeechRecognition'];
        this._recognition = new SpeechRecognition();
        // Set recognition parameters
        this._recognition.continuous = !this.utilService.isMobile(); // Keep recognizing speech continuously
        this._recognition.interimResults = true; // Return interim results (not final)
        this._recognition.lang = 'en-US'; // Set language
        // On speech recognition result
        this._recognition.onresult = (event) => {
          this.clearStopRecognitionTimeout();
          this.continuesListening = false;
          let isFinal = false;
          this._zone.run(() => {
            console.info('WakeWordComponent onresult', event)
            this.transcript = '';
            for (let i = event.resultIndex; i < event.results.length; i++) {
              this.transcript += event.results[i][0].transcript;
              isFinal = event.results[i].isFinal;
              console.info(`WakeWordComponent onresult each msg`, {
                transcript: this.transcript,
                isFinal,
              })
            }
            if (isFinal) {
              this.onSendMsg();
            }
          })
        };
        this._recognition.onnomatch = (e) => {
          console.info('WakeWordComponent onnomatch', e)
          // this.onShowHideWakeWordComponent(false);
        }
        this._recognition.onspeechend = (e) => {
          console.info('WakeWordComponent onspeechend', e)
        }
        this._recognition.onaudioend = (e) => {
          console.info('WakeWordComponent onaudioend', e)
        }
        this._recognition.onend = async (e) => {
          console.info('WakeWordComponent onend', e)
          if (this.isListening) {
            try {
              await this._recognition?.start();
            } catch (error) {
              console.info(`WakeWordComponent onend already started`)
            }
          }
        }
        // Handle errors
        this._recognition.onerror = (event) => {
          console.error('WakeWordComponent Speech recognition error detected: ' + event.error);
          console.error('WakeWordComponent Error occurred in recognition: ' + event.error);
        };
      } else {
        console.error('WakeWordComponent Your browser does not support the Web Speech API. Please use a compatible browser.');
      }
    }
    catch (error: any) {
      console.error(`WakeWordComponent initSpeechRecognition error`, error)
    }
  }

  async onSendMsg() {
    console.info(`WakeWordComponent onSendMsg called`)
    if (this.loadingAnswer || this.speakingAnswer || !this.transcript || !this.isListening) {
      // this.onStopRecognition();
      return;
    }
    this.transcripts.push({
      direction: 'guest',
      message: this.transcript
    })
    this.loadingAnswer = true;
    this._currentMsgId = this.utilService.generateMsgId(22);

    this.guestLastTranscript = this.transcript;
    this._ws.send(JSON.stringify({
      type: "verb:hook",
      msgid: this._currentMsgId,
      call_sid: this._callId,
      hook: "/prompt",
      data:
      {
        call_sid: this._callId,
        direction: "inbound",
        to: this._voiceAgentData.number,
        call_id: this._callId,
        trace_id: this._callId,
        reason: "speechDetected",
        speech: {
          alternatives: [{
            transcript: this.transcript
          }]
        }
      }
    }));
    await this.onStopRecognition();
  }
  async ngOnInit() {
    this.updateBotAnimation(this.router.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.updateBotAnimation(event.url);
      }
    });
    this._orientationSubscribe = this.utilService.getOrientation().pipe(
      tap((orientation) => {
        this._orientation = orientation;
      })
    ).subscribe();
    this._propertyConfigSubscribe = this.crudService.propertyConfig$.pipe(
      tap(() => {
        if (this.crudService.slugConfiguration$.value['propertyId'] && !this._isInitialize) {
          this._isInitialize = true;
          this.fetchPropertyAgent();
          if (this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.type === 'wake-word-chat-bot' && this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.licenceKey) {
            //  this.initOnWakeWord(this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.models); 
            //&& this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.models?.length
            this.initOnWakeWord(['genious.onnx', 'hey_vickie.onnx', 'alexa.onnx', 'hotel_genie.onnx', 'hey_genie.onnx'].map((name) => ({
              url: "./models",
              name
            })))
          }
        }
      })
    ).subscribe();
  }

  async initOnWakeWord(models: {
    name: string;
    url: string;
  }[]) {
    const licenseManager = new LicenseManager();
    // Todo: I will fetch from configuration
    const licenseKey = this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.licenceKey;
    const isLicensed = await licenseManager.isLicenseValid(licenseKey);
    if (!isLicensed) {
      alert('Invalid or expired license key.');
      return;
    }
    const threshold = 0.9999;
    const bufferCount = 2;

    models.map(async ({
      name, url
    }) => {
      try {
        const modelsFolderPath = "./models"
        // const urlInstance = new URL(url);
        // const modelsFolderPath = urlInstance.origin + '/' + urlInstance.pathname.split('/')[1];
        const keywordDetector = new KeywordDetector(
          modelsFolderPath,
          name,
          threshold,
          bufferCount,
          (detected) => this.onKeywordDetected(detected, name)
        );
        await keywordDetector.init();
        if (!this.isListening) {
          keywordDetector.startListening();
        }
        this._keywordDetector[name] = keywordDetector;
      } catch (error) {
        console.error('WakeWordComponent Initialization error:', error);
        this._statusOfWakeWordInit = 'Error initializing keyword detector.';
      }
    })
  }

  async onKeywordDetected(detected: boolean, name: string) {
    console.info("WakeWordComponent Keyword before detected!", detected, name);
    if (detected) {
      for (const key in this._keywordDetector) {
        try {
          await this._keywordDetector[key].stopListening();
        } catch (error) {
          console.error("WakeWordComponent onKeywordDetected  stopListening error!", name, error);
        }
      }
      console.info("WakeWordComponent Keyword detected!", name);
      if (!this.showWakeWordComponent && !this.phoneService.isCalling$.getValue()?.callType) {
        this._debounceOnShowHideWakeWordComponent(true)
      }
    }
  };

  fetchPropertyAgent() {
    this._callingNumbers = [];
    forkJoin(this.crudService.getData(`addressbook/all`, {
      limit: 0,
      offset: 0,
      propertyId: this.crudService.slugConfiguration$.value['propertyId']
    }),
      this.crudService.getPropertyAgent()).pipe(tap(([addressBook, voiceAgent]: any) => {
        const addressBookArr = get(addressBook, 'response_data.addressbook', []);
        addressBookArr.forEach((element) => {
          if (element.intnumber)
            this._callingNumbers.push({
              name: element.label,
              number: element.intnumber,
            })
        });
        this._voiceAgentData = get(voiceAgent, 'response_data');
        if (this._voiceAgentData) {
          this.initVoiceAiAgent();
          if (this.isTextToSpeech) {
            this.initSpeechRecognition();
          }
        }
      })).subscribe()
  }

  initVoiceAiAgent() {
    const backend = new URL(environment.VOICE_AI_ENDPOINT);
    const wsPath = `${backend.protocol === 'https:' ? 'wss:' : 'ws:'}//${backend.host}/agent/${this._voiceAgentData.socket.replace('/progress/', '')}`;
    const ws = new WebSocket(wsPath);
    ws.addEventListener('message', async (event) => {
      try {
        const message = JSON.parse(event.data);
        switch (message.type) {
          case 'command': {
            console.info("WakeWordComponent WebSocket message", message, this._currentMsgId)
            if (message.command === 'redirect') {
              const ssmlAnswer = message.data?.find((d) => d.verb === 'gather')?.say?.text || message.data?.find((d) => d.verb === 'say')?.text || ''
              this.transcript = ssmlAnswer?.replace(/<[^>]+>/g, '') || '';

              if (this.transcript && this.loadingAnswer && this.transcript !== 'OK...') {
                this.transcripts.push({
                  direction: 'bot',
                  message: this.transcript
                })
                this.botLastTranscript = this.transcript;
                if (!this.isTextToSpeech) {
                  this.speakingAnswer = false;
                  this.isListening = false;
                  this.onShowHideWakeWordComponent(true, true);
                }
              }
            }
            break;
          }
          case 'ack': {
            console.info("WakeWordComponent WebSocket ack", message.msgid, this._currentMsgId)
            if (!this.crudService.isReadyWakeWordComponent$.getValue()) {
              this.crudService.isReadyWakeWordComponent$.next(true);
              this.isReadyWakeWordComponent = true;
              setTimeout(() => {
                this.initSpeechBtn();
              }, 100);
            }
            break;
          }
          case 'textToSpeech': {
            if (this.loadingAnswer && this.isTextToSpeech) {
              if (message.chunk) {
                this._audioQueue = this._audioQueue.concat(message.chunk.data);
              } else if (message.isEnding) {
                if (message.error) {
                  console.error('WakeWordComponent unable to create text to speech', message.error);
                  this.speakingAnswer = false;  // No more audio in the queue
                  if (this.showWakeWordComponent)
                    this.onShowHideWakeWordComponent(false);
                } else {
                  const arrayBuffer = new Uint8Array(this._audioQueue).buffer;
                  const audioContext = new (window.AudioContext || window['webkitAudioContext'])();
                  const buffer = await audioContext.decodeAudioData(arrayBuffer);
                  this._customAction = message.customAction;
                  if (this.crudService._audioContext) {
                    this.playAudio(buffer, audioContext);
                  } else {
                    this.speakingAnswer = false;  // No more audio in the queue
                    console.info('WakeWordComponent _audioContext has not found');
                    this.isListening = false;
                    if (this._customAction) {
                      this._handleCustomerActions(this._customAction);
                      this._customAction = null;
                      this.onShowHideWakeWordComponent(false, false);
                    } else {
                      this.onShowHideWakeWordComponent(true, true);
                    }
                  }
                }
              }
            }
            break;
          }
          case 'onlyCustomAction': {
            if (!this.isTextToSpeech) {
              this._customAction = message.customAction;
              this.speakingAnswer = false;
              this.isListening = false;
              if (this._customAction) {
                this._handleCustomerActions(this._customAction);
                this._customAction = null;
                this.onShowHideWakeWordComponent(false, false);
              } else {
                this.onShowHideWakeWordComponent(true, true);
              }
            }
            break;
          }
        }
        console.info({ message }, 'WakeWordComponent WebSocket response data');
      }
      catch (e) {
        console.info({ event, e }, 'WakeWordComponent WebSocket bad data');
      }
    });
    ws.addEventListener('error', (error) => {
      console.info({ error }, 'WakeWordComponent WebSocket error');
    });
    ws.addEventListener('close', (close) => {
      console.info({ close }, 'WakeWordComponent WebSocket close', close?.code === 1006);
      if (close?.code === 1006) {
        console.info('WakeWordComponent onclose via server', close);
        this._ws.removeAllListeners();
        this._ws = null;
        this.initVoiceAiAgent();
      }
    });
    ws.addEventListener('open', (open) => {
      console.info({ open }, 'WakeWordComponent WebSocket open');
      this._currentMsgId = this.utilService.generateMsgId(22);
      this._ws.send(JSON.stringify(
        {
          type: "session:new",
          msgid: this._currentMsgId,
          call_sid: this._callId,
          data:
          {
            direction: "inbound",
            trace_id: this._callId,
            caller_name: `${localStorage.getItem('firstName')}`,
            room_number: localStorage.getItem('room'),
            callingNumbers: this._callingNumbers,
            call_sid: this._callId,
            to: this._voiceAgentData.number,
            call_id: this._callId,
            defaults: {},
            text_to_speech: this.isTextToSpeech
          }
        }
      ));
      if (this._pingTimeoutInstance) {
        clearInterval(this._pingTimeoutInstance);
        this._pingTimeoutInstance = null
      }
      this._pingTimeoutInstance = setInterval(() => {
        this._ws.send(JSON.stringify(
          {
            type: "ping",
            msgid: this._currentMsgId,
            call_sid: this._callId,
            data:
            {
              direction: "inbound",
              trace_id: this._callId,
              caller_name: `${localStorage.getItem('firstName')}`,
              room_number: localStorage.getItem('room'),
              callingNumbers: this._callingNumbers,
              call_sid: this._callId,
              to: this._voiceAgentData.number,
              call_id: this._callId,
              defaults: {},
            }
          }
        ));
      }, 30000); // send ping every 30 seconds
    });
    this._ws = ws;
  }

  initSpeechBtn() {
    this._wakeWordAnimation.canvas = document.getElementById("canvas-ele") as HTMLCanvasElement;
    if (!this._wakeWordAnimation.canvas) {
      return;
    }
    this._wakeWordAnimation.canvas.width = this.botAnimation.width;
    this._wakeWordAnimation.canvas.height = this.botAnimation.height;
    this._wakeWordAnimation.ctx = this._wakeWordAnimation.canvas.getContext("2d");
    this._wakeWordAnimation.canvasX = this._wakeWordAnimation.canvas.width / 2;
    this._wakeWordAnimation.canvasY = this._wakeWordAnimation.canvas.height / 2;
    // Particle properties
    const particleCount = 200; // Keep this number for a denser outer ring
    const particles = [];
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        angle: (Math.PI * 2 * i) / particleCount, // Evenly distribute particles
        radius: (this._wakeWordAnimation.canvasX) // Initial radius for particles
      });
    }
    this._wakeWordAnimation.particles = [...particles];
    this._analyserInstance.analyser = null;
    this._analyserInstance.dataArray = null;
    this.draw(0, 0);
  }


  interpolateColors(color1, color2, factor) {
    const r = Math.round(color1.r + factor * (color2.r - color1.r));
    const g = Math.round(color1.g + factor * (color2.g - color1.g));
    const b = Math.round(color1.b + factor * (color2.b - color1.b));
    return `rgb(${r}, ${g}, ${b})`;
  }

  draw(previousAmplitudeInner: number = 0, colorShift: number = 0) {
    if (!this._wakeWordAnimation.canvas) {
      return;
    }
    // console.info(`Calling draw`, {
    //   previousAmplitudeInner, colorShift,
    //   analyser: this._analyserInstance.analyser
    // })
    if (this._analyserInstance.analyser && (this.isListening || this.speakingAnswer)) {
      requestAnimationFrame(() => this.draw(previousAmplitudeInner, colorShift));
      this._analyserInstance.analyser.getByteFrequencyData(this._analyserInstance.dataArray);
    }
    // Calculate average amplitude
    let sum = 0;
    if (this._analyserInstance.dataArray) {
      for (let i = 0; i < this._analyserInstance.dataArray.length; i++) {
        sum += this._analyserInstance.dataArray[i];
      }
    }
    let averageAmplitude = sum / (this._analyserInstance.dataArray ? this._analyserInstance.dataArray.length : 1);

    // Apply smoothing (Rolling average) for inner ring
    let smoothedAmplitudeInner =
      previousAmplitudeInner * 0.7 + averageAmplitude * 2.3;
    previousAmplitudeInner = smoothedAmplitudeInner;

    // Apply minimum threshold
    if (smoothedAmplitudeInner < 10) smoothedAmplitudeInner = 0;

    this._wakeWordAnimation.ctx.clearRect(0, 0, this._wakeWordAnimation.canvas.width, this._wakeWordAnimation.canvas.height);

    // Draw the static outer circle (green)
    if (this.showWakeWordComponent) {
      this._wakeWordAnimation.ctx.beginPath();
      this._wakeWordAnimation.ctx.arc(this._wakeWordAnimation.canvasX, this._wakeWordAnimation.canvasY, (this._wakeWordAnimation.canvasX), 0, 2 * Math.PI);
      this._wakeWordAnimation.ctx.strokeStyle = "#0f0"; // Blue color
      this._wakeWordAnimation.ctx.lineWidth = 7; // Line width for the outer circle
      this._wakeWordAnimation.ctx.stroke();
    }
    // Interpolate between the two colors for the glow circle
    colorShift += 0.055; // Slow down the transition
    const factor = (Math.sin(colorShift) + 1) / 2; // Normalize sin value to [0, 1]
    const color = this.interpolateColors(color1, color2, factor);

    // Draw the glowing circle (smaller than the green circle)
    this._wakeWordAnimation.ctx.beginPath();
    this._wakeWordAnimation.ctx.arc(this._wakeWordAnimation.canvasX, this._wakeWordAnimation.canvasY, this._wakeWordAnimation.canvasX - 3, 0, 2 * Math.PI);
    this._wakeWordAnimation.ctx.fillStyle = color;
    this._wakeWordAnimation.ctx.fill();

    // Draw the inner circle that moves inward based on audio
    let innerRadius =
      (this._wakeWordAnimation.canvasX - 3) - smoothedAmplitudeInner / audioAmplifierInner;

    // Ensure the inner circle doesn't shrink past the center ring
    // if (innerRadius < (this._wakeWordAnimation.canvasX - 3) / 2) {
    //   innerRadius = (this._wakeWordAnimation.canvasX - 3) / 2;
    // }

    // this._wakeWordAnimation.ctx.beginPath();
    // this._wakeWordAnimation.ctx.arc(this._wakeWordAnimation.canvasX, this._wakeWordAnimation.canvasY, innerRadius, 0, 2 * Math.PI);
    // this._wakeWordAnimation.ctx.strokeStyle = "#558EC1"; // Green color 0f0 OR 558EC1
    // this._wakeWordAnimation.ctx.lineWidth = innerLineWidth; // Thicker line for the inner circle
    // this._wakeWordAnimation.ctx.stroke();
    // Draw the particles for the outer circle
    if (this.isListening) {
      this._wakeWordAnimation.particles.forEach((particle) => {
        const particleRadius =
          (this._wakeWordAnimation.canvasX) + (averageAmplitude / audioAmplifierOuter) * Math.random();
        const px = this._wakeWordAnimation.canvasX + particleRadius * Math.cos(particle.angle);
        const py = this._wakeWordAnimation.canvasY + particleRadius * Math.sin(particle.angle);
        this._wakeWordAnimation.ctx.beginPath();
        this._wakeWordAnimation.ctx.arc(px, py, 1.5, 0, 2 * Math.PI); // Small circle as particle
        this._wakeWordAnimation.ctx.fillStyle = "#22375A"; // Particle color
        this._wakeWordAnimation.ctx.fill();
      });
    }
    // Vibrate and color-change the SVG based on audio
    const vibration = smoothedAmplitudeInner / audioAmplifierSVG; // Calculate vibration effect
    const svgElement = document.getElementById("vibrating-svg");
    const paths = svgElement.querySelectorAll("path");
    if (this.speakingAnswer) {
      // Interpolate between dark blue (#22375A) and green (#00FF00) based on amplitude, using the color amplifier
      const svgColorFactor = Math.min(smoothedAmplitudeInner / audioAmplifierSVGColor, 1);
      const svgColor = this.interpolateColors(
        { r: 0, g: 0, b: 0 },   // Dark blue (#22375A)
        { r: 0, g: 255, b: 0 },    // Green (#00FF00)
        svgColorFactor
      );
      // Apply the color and vibration to the SVG element
      paths.forEach(path => path.setAttribute("fill", svgColor));
      const transformValue = `translate(${Math.random() * vibration - vibration / 2
        }px, ${Math.random() * vibration - vibration / 2}px) scale(${1 + svgColorFactor * 0.1})`;
      svgElement.style.transform = transformValue;
    } else {
      paths.forEach(path => path.setAttribute("fill", `rgb(4,18,77)`));
    }
  }

  async onShowHideWakeWordComponentByBtn(showWakeWordComponent) {
    if (showWakeWordComponent && this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.type === 'wake-word-chat-bot') {
      for (const key in this._keywordDetector) {
        try {
          await this._keywordDetector[key].stopListening();
        } catch (error) {
          console.error("WakeWordComponent onShowHideWakeWordComponentByBtn  stopListening error!");
        }
      }
    }
    if (!this.phoneService.isCalling$.getValue()?.callType) {
      this.onShowHideWakeWordComponent(showWakeWordComponent)
    }
  }

  async onShowHideWakeWordComponent(showWakeWordComponent: boolean, continuesListening: boolean = false) {
    if (this.phoneService.isCalling$.getValue()?.callType) {
      showWakeWordComponent = false;
      continuesListening = false;
    }
    this.continuesListening = continuesListening;
    this.clearStopRecognitionTimeout();
    if (this.continuesListening && this.isTextToSpeech) {
      this._clearStopRecognitionTimeout = setTimeout(async () => {
        await this.onShowHideWakeWordComponent(false);
      }, continuesListeningTimeout);
    }
    this._audioQueue = []
    this.showWakeWordComponent = showWakeWordComponent;
    if (this._audioInstance)
      this._audioInstance.onended = null;
    this._audioInstance?.stop();
    this._customAction = null;
    if (this.showWakeWordComponent) {
      this.loadingAnswer = false;
      this.speakingAnswer = false;
      this.onStartRecognition();
    } else {
      await this.onStopRecognition();
      this.loadingAnswer = false;
      this.transcripts = [];
      this.botLastTranscript = '';
      this.guestLastTranscript = '';
      if (this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.type === 'wake-word-chat-bot') {
        setTimeout(async () => {
          for (const key in this._keywordDetector) {
            try {
              await this._keywordDetector[key].startListening();
            } catch (error) {
              console.error("WakeWordComponent onShowHideWakeWordComponent false  startListening error!", error);
            }
          }
        }, 100);
      }

    }
  }

  async onStartRecognition() {
    try {
      console.info(`WakeWordComponent onStartRecognition called`)
      this.transcript = '';
      let analyser = null;
      let dataArray = null;
      if (!this.utilService.isMobile() && this.isTextToSpeech) {
        const stream = await navigator.mediaDevices
          .getUserMedia({ audio: true })
        const audioContext = new (window.AudioContext ||
          window['webkitAudioContext'])();
        const source = audioContext.createMediaStreamSource(stream);
        analyser = audioContext.createAnalyser();
        source.connect(analyser);
        analyser.fftSize = 1024;
        const bufferLength = analyser.frequencyBinCount;
        dataArray = new Uint8Array(bufferLength);
      }
      try {
        if (this.isTextToSpeech)
          await this._recognition?.start();
      } catch (error) {
        console.info(`WakeWordComponent already started`)
      }
      this.isListening = true;
      this._analyserInstance.analyser = analyser;
      this._analyserInstance.dataArray = dataArray;
      this.draw(0, 0);
    } catch (error) {
      console.error("WakeWordComponent onStartRecognition:", error);
      this.isListening = false;
      this.loadingAnswer = false;
      this.speakingAnswer = false;
    }

  }

  async onStopRecognition() {
    try {
      console.info(`WakeWordComponent onStopRecognition called`)
      try {
        if (this.isTextToSpeech)
          await this._recognition?.stop();
      } catch (error) {
        console.info(`WakeWordComponent already stoped`)
      }
      this.isListening = false;
      this.speakingAnswer = false;
      this._analyserInstance.analyser = null;
      this._analyserInstance.dataArray = null;
      this.draw(0, 0);
    } catch (error) {
      console.error("WakeWordComponent onStopRecognition:", error);
    }
  }

  ngOnDestroy(): void {
    console.info("WakeWordComponent ngOnDestroy:");
    if (this._pingTimeoutInstance) {
      clearInterval(this._pingTimeoutInstance);
      this._pingTimeoutInstance = null
    }
    this.onStopRecognition();
    this._ws?.close();
    if (this._audioInstance)
      this._audioInstance.onended = null;
    this._audioInstance?.stop();
    this._customAction = null;
    this.crudService.isReadyWakeWordComponent$.next(false);
    this._orientationSubscribe?.unsubscribe();
    this._propertyConfigSubscribe?.unsubscribe();
    if (this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.type === 'wake-word-chat-bot') {
      for (const key in this._keywordDetector) {
        try {
          this._keywordDetector[key].stopListening();
        } catch (error) {
          console.error("WakeWordComponent ngOnDestroy  stopListening error!", error);
        }
      }
    }

  }


  playAudio(buffer, audioContext: AudioContext) {
    try {
      this.continuesListening = false;
      const source = audioContext.createBufferSource();
      this._audioInstance = source;
      source.buffer = buffer;
      const analyser = audioContext.createAnalyser();
      // Schedule the source to play
      source.start(audioContext.currentTime + 0);
      source.connect(analyser);
      analyser.connect(audioContext.destination);
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      // When the current buffer ends, try to play the next one
      source.onended = () => {
        this.speakingAnswer = false;  // No more audio in the queue
        console.info('WakeWordComponent Audio has ended');
        this.isListening = false;
        if (this._customAction) {
          this._handleCustomerActions(this._customAction);
          this._customAction = null;
          this.onShowHideWakeWordComponent(false, false);
        } else {
          this.onShowHideWakeWordComponent(true, true);
        }
      };
      this.loadingAnswer = false;
      this.speakingAnswer = true;
      this._analyserInstance.analyser = analyser;
      this._analyserInstance.dataArray = dataArray;
      this.draw(0, 0);
    } catch (error) {
      console.error('WakeWordComponent playNextInQueue', error);
      this.speakingAnswer = false;  // No more audio in the queue
      if (this.showWakeWordComponent)
        this.onShowHideWakeWordComponent(false);
    }
  }

  clearStopRecognitionTimeout() {
    if (this._clearStopRecognitionTimeout) {
      clearTimeout(this._clearStopRecognitionTimeout);
      this._clearStopRecognitionTimeout = null;
    }
  }

  private _handleCustomerActions(_customAction) {
    switch (_customAction.name) {
      case 'dial': {
        const dialNumber = _customAction.customActionData?.number;
        if (dialNumber) {
          if (this._orientation === 'landscape') {
            this.phoneService.keypadModel.next({
              to: dialNumber || "", showKeypad: true
            });
            this.phoneService.callTo({ to: dialNumber || "", propertyConfig: this.crudService.propertyConfig$.value });
            if (!this.router.url.includes('home')) {
              this.router.navigate(['home']);
            }
          } else {
            if (!this.router.url.includes('dialer')) {
              this.router.navigate(['dialer'], {
                state: {
                  options: {
                    dialNumber,
                  }
                }
              });
            }
            this.phoneService.keypadModel.next({
              to: dialNumber || "", showKeypad: true
            });
            this.phoneService.callTo({ to: dialNumber || "", propertyConfig: this.crudService.propertyConfig$.value });
          }
        }
        break;
      }
      case 'hangup': {
        this.onShowHideWakeWordComponent(false)
        break;
      }
      default:
        break;
    }
  }
  updateBotAnimation(url: string) {
    if (url === '/home') {
      console.info(`WakeWordComponent at home`)
      this.botAnimation = {
        width: 50,
        height: 50,
        top: '14%'
      };
      this.canvasClass = 'home-canvas';
    } else {
      console.info(`WakeWordComponent at not home`)
      this.botAnimation = {
        width: 30,
        height: 30,
        top: '25%'
      }
      this.canvasClass = 'other-canvas';
    }
    if (this._wakeWordAnimation?.canvas) {
      this._wakeWordAnimation.canvas.height = this.botAnimation.height;
      this._wakeWordAnimation.canvas.width = this.botAnimation.width;
      this._wakeWordAnimation.canvasX = this._wakeWordAnimation.canvas.width / 2;
      this._wakeWordAnimation.canvasY = this._wakeWordAnimation.canvas.height / 2;
      const factor = (Math.sin(0) + 1) / 2; // Normalize sin value to [0, 1]
      const color1 = { r: 159, g: 192, b: 252 }; // #93DEEE
      const color2 = { r: 200, g: 200, b: 200 };
      const color = this.interpolateColors(color1, color2, factor);
      this._wakeWordAnimation.ctx.beginPath();
      this._wakeWordAnimation.ctx.arc(this._wakeWordAnimation.canvasX, this._wakeWordAnimation.canvasY, this._wakeWordAnimation.canvasX - 3, 0, 2 * Math.PI);
      this._wakeWordAnimation.ctx.fillStyle = color;
      this._wakeWordAnimation.ctx.fill();
    }
  }


  get isTextToSpeech() {
    return ['voice-chat-bot', 'wake-word-chat-bot'].includes(this.crudService.pwaConfiguration$?.value?.aiBotConfiguration?.type) && this.utilService.hasMicrophonePermission$.value;
  }

}
